import React, { useEffect, useState } from 'react';
import logoImage from '../../assets/images/mundl_logo.jpeg';

const Navbar = () => {

  return (
    <nav className="navbar navbar-expand-lg navbar-dark fixed-top" style={{ backgroundColor: '#0000A2' }}>
      <div className="container-fluid">
        <a className="navbar-brand" href="www.mlconsul.com">
          <img src={logoImage} alt="Logo" width="50" height="24" className="d-inline-block align-text-top shadow-lg rounded tw-mr-2" />
          Dashboard Juniper Mist 
        </a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <DateTimeComponent />
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}


const DateTimeComponent = () => {
  const [currentDateTime, setCurrentDateTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <span className='text-white tw-text-lg tw-font-bold'>
      {currentDateTime.toLocaleString('en-US', {
        hour12: false,
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      })}
    </span>
  );
};

export default Navbar