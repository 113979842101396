import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
//import MLSysLogo from '../assets/images/mlsys_logo.png'
import MLGroup from '../assets/images/mlgroup_background.PNG'
import MLGroupLogo from '../assets/images/corporate_logo.jpeg'

const Login = () => {
    const [emailInput, setEmailInput] = useState('');
    const [emailSend, setEmailSend] = useState(false);
    const [emailStatus, setEmailStatus] = useState('empty')

    const handleSendEmail = async (e) => {
        e.preventDefault();
        if (emailInput.trim() === '') return;


        setEmailSend(true);

        try {
            const config = {
                headers: {
                    'Authorization': 'Bearer TSZMIENvcnBvcmF0ZSBHcm91cCwgQUcsIFN5c3RlbXMsIA=='
                }
            };

            const response = await axios.post('https://junipermist-gxuxu.ondigitalocean.app/api/v1/login/', 
            //const response = await axios.post('http://172.25.64.54:8000/api/v1/login/',
                {
                    email: emailInput.trim(),
                },
                config);
            if (response.data.email) {
                localStorage.setItem("email", response.data.email);
                console.log(response.data.email)
                setEmailStatus('success');
            } else {
                setEmailStatus('error');
            }
            console.log(emailStatus)
        } catch (error) {
            console.error('Error:', error);
            setEmailStatus('error');
        }
    };

    return (
        <div class="tw-h-screen justify-content-center d-flex align-items-center" style={{ backgroundImage: `url(${MLGroup})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
            <section>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-xxl-11">
                            <div className="card border-light-subtle shadow-sm">
                                <div className="row g-0">
                                    {/*<div className="col-12 col-md-6 tw-relative">
                                        <img className='tw-absolute tw-top-3 tw-left-3 tw-w-44 tw-h-20 tw-z-10' src={MLSysLogo} alt='ML System Logo' width="175" height="57" />
                                        <img className="img-fluid rounded-start w-100 h-100 object-fit-cover" loading="lazy" src={MLSysBackground} alt="Welcome back you've been missed!" />
                                    </div>*/}
                                    <div className="col-12 col-md-12 d-flex align-items-center justify-content-center">
                                        <div className="col-12 col-lg-12 col-xl-12">
                                            <div className="card-body p-3 p-md-4 p-xl-5">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="mb-5">
                                                            <div className="text-center mb-4">
                                                                <a href="#!" className="tw-inline-block">
                                                                    <img src={MLGroupLogo} alt="ML AG Logo" width="175" height="57" />
                                                                </a>
                                                            </div>
                                                            <h4 className="text-center">Welcome to Juniper Mist's Dashboard !</h4>
                                                        </div>
                                                    </div>
                                                </div>

                                                {!emailSend || emailStatus === 'empty' ? (
                                                    <>
                                                        <form onSubmit={handleSendEmail}>
                                                            <div className="container">
                                                                <div className="row">
                                                                    <div className="col-12">
                                                                        <div className="form-floating mb-3">
                                                                            <input type="email" className="form-control" name="email" id="email" placeholder="name@example.com" value={emailInput} onChange={(e) => setEmailInput(e.target.value)} required />
                                                                            <label for="email" className="form-label">Email</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-12  mb-3">
                                                                        <div className="form-check">
                                                                            <input className="form-check-input" type="checkbox" name="remember_me" id="remember_me" />
                                                                            <label className="form-check-label text-secondary" for="remember_me">
                                                                                Keep me logged in
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">

                                                                    <div className="col-12">
                                                                        <div className="d-grid">
                                                                            <button className="btn btn-dark btn-lg fw-bold" type="submit" style={{ backgroundColor: '#0000A2' }} onClick={(e) => setEmailSend(true)}>Login</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className="d-flex gap-2 gap-md-4 flex-column flex-md-row justify-content-md-center mt-5 text-center">
                                                                    <Link to="/signup" className="link-secondary text-decoration-none" >Create new account</Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                                    :
                                                    <> {
                                                        emailStatus === 'success'
                                                            ? (
                                                                <div className="row">
                                                                    <div className="col-12">
                                                                        <div className="mb-5">
                                                                            <h6 className="text-center">The Dashboard connection link has been sent - please check your mailbox</h6>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) :
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <div className="mb-5">
                                                                        <h6 className="text-center tw-text-red-900">This Email does not exist please contact the administrator.</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    }

                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Login;