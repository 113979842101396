import React from 'react';
import { Link } from 'react-router-dom';
//import MLSysBackground from '../assets/images/mlsys_background.jpg'
import MLGroup from '../assets/images/mlgroup_background.PNG'
import MLGroupLogo from '../assets/images/corporate_logo.jpeg'

const Signup = () => {
    return (
        <div class="tw-h-screen justify-content-center d-flex align-items-center" style={{ backgroundImage: `url(${MLGroup})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
            <section>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-12">
                            <div className="card border-light-subtle shadow-sm">
                                <div className="row g-0">
                                    {/*<div className="col-12 col-md-6 tw-relative">
                                        <img className='tw-absolute tw-top-3 tw-left-3 tw-w-44 tw-h-20 tw-z-10' src={MLSysLogo} alt='ML System Logo' width="175" height="57" />
                                        <img className="img-fluid rounded-start w-100 h-100 object-fit-cover" loading="lazy" src={MLSysBackground} alt="Welcome back you've been missed!" />
                                    </div>*/}
                                    <div className="col-12 col-md-12 d-flex align-items-center justify-content-center">
                                        <div className="col-12 col-lg-12 col-xl-12">
                                            <div className="card-body p-3 p-md-4 p-xl-5">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="mb-5">
                                                            <div className="text-center mb-4">
                                                                <a href="#!" className="tw-inline-block">
                                                                    <img src={MLGroupLogo} alt="ML AG Logo" width="175" height="57" />
                                                                </a>
                                                            </div>
                                                            <h4 className="text-center">Welcome to Juniper Mist's Dashboard !</h4>
                                                        </div>
                                                    </div>
                                                </div>

                                                <form action="#!">
                                                    <div className="containter">
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className="form-floating mb-3">
                                                                    <input type="email" className="form-control" name="email" id="email" placeholder="name@example.com" required />
                                                                    <label for="email" className="form-label">Email</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className="form-floating mb-3">
                                                                    <input type="text" className="form-control" name="fname" id="fname" placeholder="You First Name" required />
                                                                    <label for="fname" className="form-label">First Name</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className="form-floating mb-3">
                                                                    <input type="text" className="form-control" name="lname" id="lname" placeholder="You Last Name" required />
                                                                    <label for="lname" className="form-label">Last Name</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/*<div className="row">
                                                            <div className="col-12">
                                                                <div className="form-floating mb-3">
                                                                    <select className="form-select tw-block tw-w-full tw-px-3 tw-py-2 tw-text-base tw-font-medium tw-text-gray-700 tw-bg-white tw-bg-clip-padding tw-bg-no-repeat tw-border tw-border-solid tw-border-gray-300 tw-rounded tw-transition tw-ease-in-out tw-m-0 focus:tw-text-gray-700 focus:tw-bg-white focus:tw-border-blue-600 focus:tw-outline-none" aria-label="Wlans"
                                                                        value='{selectedAP}'
                                                                    >
                                                                        <option key='M&L' value='M&L'>M&L</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>*/}
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className="d-grid">
                                                                    <button className="btn btn-dark btn-lg fw-bold" type="submit" style={{ backgroundColor: '#0000A2' }}>Sign up</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="d-flex gap-2 gap-md-4 flex-column flex-md-row justify-content-md-center mt-5 text-center">
                                                            <Link to="/login" className="link-secondary text-decoration-none" >Log in with your e-mail</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Signup;