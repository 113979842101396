import React, { useState, useEffect } from 'react';
import { fetchWithAuth } from '../../api/apiService';

const TopWlansUsage = ({ authToken }) => {
  const [wlans, setWlans] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchWithAuth('api/v1/usage/top_wlan_usage', authToken);
        setWlans(response.data);
      } catch (error) {
        console.error('Could not fetch the data', error);
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();

    const intervalId = setInterval(fetchData, 600000); 
    return () => clearInterval(intervalId);
    
  }, [authToken]);

  if (isError) {
    return (
        <div className='d-flex justify-content-center align-items-center' style={{ width: '100%', height: '200px' }}>
            <h3 className='tw-text-lg tw-font-semibold tw-text-gray-800'>Could not fetch the data</h3>
        </div>
    );
}

  if (isLoading) {
    return (
      <div className='d-flex justify-content-center align-items-center' style={{ width: '100%', height: '400px' }}>
          <div class="spinner-border" text-primary style={{ width: '3rem', height: '3rem' }} role="status">
              <span class="sr-only"></span>
          </div>
      </div>
  );
  }

  return (
    <div >
       <div className='tw-text-xl tw-font-bold tw-p-2 tw-text-center tw-text-[#0000A2]'>
      Top Usage Wlan <strong>({wlans.length})</strong>
      </div>
      <div className='tw-max-h-[400px] tw-overflow-y-auto'>
      <table className='table caption-top table-sm tw-min-w-full'>
        <thead style={{position: 'sticky' , top: 0 ,  zIndex: 1, backgroundColor: '#0000A2', color: 'white', boxShadow: '0 2px 4px rgba(0,0,0,0.1)'}}> 
          <tr>
            <th className='p-2'>SSID</th>
            <th className='p-2'>Total Bytes</th>
            <th className='p-2'>Number of Clients</th>
          </tr>
        </thead>
        <tbody>
          {wlans.map((wlan, index) => (
            <tr key={index} className='hover:tw-bg-blue-300'>
              <td className='p-2'>{wlan.ssid}</td>
              <td className='p-2'>{wlan.total_bytes}</td>
              <td className='p-2'>{wlan.number_clients}</td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
    </div>
  );
};

export default TopWlansUsage;
