import React, { useState, useEffect } from 'react';
import { fetchWithAuth } from '../../api/apiService';

const TopClientsUsage = ({ authToken }) => {
  const [clients, setClients] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchWithAuth('api/v1/clients/top_client_usage', authToken);
        setClients(response.data);
      } catch (error) {
        console.error('Could not fetch the data', error);
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();

    const intervalId = setInterval(fetchData, 600000);
    return () => clearInterval(intervalId);

  }, [authToken]);

  if (isError) {
    return (
        <div className='d-flex justify-content-center align-items-center' style={{ width: '100%', height: '200px' }}>
            <h3 className='tw-text-lg tw-font-semibold tw-text-gray-800'>Could not fetch the data</h3>
        </div>
    );
}

  if (isLoading) {
    return  (
      <div className='d-flex justify-content-center align-items-center' style={{ width: '100%', height: '200px' }}>
          <div class="spinner-border" text-primary style={{ width: '3rem', height: '3rem' }} role="status">
              <span class="sr-only"></span>
          </div>
      </div>
  );
  }

  return (
    <div className='mt-5'>
      <div className='tw-text-xl tw-font-bold tw-p-2 tw-text-center tw-text-[#0000A2]'>
        Top Usage Client <strong>({clients.length})</strong>
      </div>
      <div className='tw-max-h-[400px] tw-overflow-y-auto'>
        <table className='table table-light caption-top table-sm tw-min-w-full'>
        <thead style={{position: 'sticky' , top: 0 ,  zIndex: 1, backgroundColor: '#0000A2', color: 'white', boxShadow: '0 2px 4px rgba(0,0,0,0.1)'}}> 
          <tr>
              <th className='p-2'>Name</th>
              <th className='p-2'>Mac Address</th>
              <th className='p-2'>IPv4 Address</th>
              <th className='p-2'>Device Type</th>
              <th className='p-2'>Protocol</th>
              <th className='p-2'>Band</th>
              <th className='p-2'>RSSI</th>
              <th className='p-2'>SSID</th>
              <th className='p-2' >SNR</th>
              <th className='p-2'>Total Bytes</th>
              <th className='p-2'>% Bytes</th>
              <th className='p-2'>Connected Time</th>
            </tr>
          </thead>
          <tbody>
            {clients.map((client, index) => (
              <tr key={index} className='hover:tw-bg-blue-300'>
                <td className='p-2'>{client.name}</td>
                <td className='p-2'>{client.mac}</td>
                <td className='p-2'>{client.ipv4}</td>
                <td className='p-2'>{client.device_type}</td>
                <td className='p-2'>{client.protocol}</td>
                <td className='p-2'> {client.band}</td>
                <td className='p-2'>{client.rssi}</td>
                <td className='p-2'>{client.ssid}</td>
                <td className='p-2'>{client.snr}</td>
                <td className='p-2'>{client.total_byte}</td>
                <td className='p-2'>{client.procent_byte}</td>
                <td className='p-2'>{client.connected_time}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TopClientsUsage;
