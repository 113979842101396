import React, { useEffect, useState } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import axios from 'axios';
import { ReactComponent as UpArrow } from '../assets/images/up-arrow.svg'; 
import TopClientsUsage from '../components/home/TopClientsUsage';
import TopAppsUsage from '../components/home/TopAppsUsage';
import TopWlansUsage from '../components/home/TopWlansUsage';
import TopWlansUsageChartClient from '../components/home/TopWlansUsageChartClient';
import TopWlansUsageChartByte from '../components/home/TopWlansUsageChartByte';
//import TopDevicesTypeUsage from '../components/TopDevicesTypeUsage';
import ClientsTableComponent from '../components/home/ClientsTableComponent';
import Navbar from '../components/home/Navbar';
import PostConnectionByte from '../components/home/PostConnectionByte';
import PostConnectionClient from '../components/home/PostConnectionClient';
//import PostConnectionApps from '../components/PostConnectionApps';
import ChanelUt from '../components/home/ChanelUt';
import PostConnectionByteRxTx from '../components/home/PostConnectionByteRxTx';


const authToken = 'TSZMIENvcnBvcmF0ZSBHcm91cCwgQUcsIFN5c3RlbXMsIA==';

const Home = () => {
  const [isValidToken, setIsValidToken] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const token = query.get('token');

    if (!token) {
      // Si aucun token n'est trouvé, redirigez ou affichez un message.
      console.log('No token provided');
      setIsLoading(false);
      return;
    }
    verifyToken(token);
  }, [location]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 100) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const verifyToken = async (token) => {
    try {
      //const response = await axios.get(`http://localhost:8000/api/v1/dashboard?token=${token}`);
      const response = await axios.get(`https://junipermist-gxuxu.ondigitalocean.app/api/v1/dashboard?token=${token}`);
      if (response.status === 200) {
        setIsValidToken(true);
      } else {
        setIsValidToken(false);
      }
    } catch (error) {
      console.error('Error verifying token:', error);
      setIsValidToken(false);
    }
    setIsLoading(false);
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  if (isLoading) {
    return (
      <div className='d-flex justify-content-center align-items-center' style={{ width: '100%', height: '100%' }}>
      <div class="spinner-border" text-primary style={{ width: '3rem', height: '3rem' }} role="status">
        <span class="sr-only"></span>
      </div>
    </div>
    );
  }

  if (!isValidToken) {
    return <Navigate to="/login" replace />;
  }

  return (
    <div style={{ fontFamily: 'sans-serif' }}>
      <Navbar />
      <div className='container-fluid tw-pt-20' style={{ fontFamily: 'sans-serif' }}>
        <div className='row g-3 tw-mb-5'>
          <div className='col-sm-12 col-md-6 col-lg-4 sm:tw-m-1 md:tw-m-0'>
            <PostConnectionClient authToken={authToken} />
          </div>
          {/*<div className='col-3'>
            <PostConnectionApps authToken={authToken} />
  </div>*/}
          <div className='col-sm-12 col-md-6 col-lg-4 sm:tw-m-1 md:tw-m-0'>
            <PostConnectionByte authToken={authToken} />
          </div>
          <div className='col-sm-12 col-md-6 col-lg-4 sm:tw-m-1 md:tw-m-0'>
            <PostConnectionByteRxTx authToken={authToken} />
          </div>
        </div>
       {/* <div className='mb-5'>
          <ChanelUt authToken={authToken} />
</div>*/}

        <div className='row g-4  tw-mb-5'>
          <div className='col-md-6 col-sm-12'>
            <ClientsTableComponent authToken={authToken} />
          </div>
          <div className='col-md-6 col-sm-12'>
            <div className='row tw-mb-3'>
              <div className='col-md-6 col-sm-12 sm:tw-mb-3 md:tw-mb-0'>
                <TopWlansUsageChartClient authToken={authToken} />
              </div>
              <div className='col-md-6 col-sm-12 sm:tw-mt-3 md:tw-mb-0'>
                <TopWlansUsageChartByte authToken={authToken} />
              </div>
            </div>
            <div className='row tw-mb-3'>
              <div className='col-12'>
                <TopWlansUsage authToken={authToken} />
              </div>
            </div>
          </div>
        </div>
        <div className='row tw-mb-5'>
          <div className='col-md-6 col-sm-12 sm:tw-mb-2'>
            <TopAppsUsage authToken={authToken} />
          </div>
          <div className='col-md-6 col-sm-12 sm:tw-mb-2'>
            <ChanelUt authToken={authToken} />
          </div>
        </div>
        <div className='row tw-mt-5'>
          <div className='col-12'>
            <TopClientsUsage authToken={authToken} />
          </div>
        </div>
        {showScrollButton && (
          <button onClick={scrollToTop} style={{
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            zIndex: 1000
          }}>
            <UpArrow style={{ width: '50px', height: '50px' }}/>
          </button>
        )}
      </div>
    </div>
  );
};

export default Home;
